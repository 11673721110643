import {bindable, customElement} from 'aurelia-framework';

@customElement('sio-iframe')

export class Iframe {

    @bindable config;

    bind() {
        window.addEventListener("message", this.onMessage.bind(this), false);
        this.regex = this.config?.options?.regex
console.log(this.regex)
    }

    onMessage(event) {

        const str = JSON.stringify(event.data);
        console.log(this.regex,str,new RegExp(this.regex), new RegExp(this.regex).test(str))
        if (new RegExp(this.regex).test(str)) {
            console.log(str)
            this.config?.setValue(str);
        }
    }

    deactivate() {
        window.removeEventListener("message", this.onMessage);
    }


}
