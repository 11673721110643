import {api, onQueryStarted} from "../../store/api";

const costsApi = api.injectEndpoints({
    endpoints: build => ({
        items: build.query({
            query({id, modelId}) {
                const params = new URLSearchParams()
                params.set("conditions", JSON.stringify({product: {$eq: {id, modelId}}}))
                params.set("embeds[]", "order")

                return "order/order-item?" + params.toString()
            }
        }),

        realCosts: build.query({
            query: ({origin, currency, prices = []}) => ({
                url: "costs/query/real-costs/origin",
                method: "POST",
                body: {origin, currency, prices}
            }),
            forceRefetch: () => true, // @todo improve this
        }),

        realCostsSave: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/set-prices",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted()
        }),

        realCostsDeleteAssignment: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/delete-assignment",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted("Erfolgreich gelöscht")
        }),

        realCostsAddAssignments: build.mutation({
            query: body => ({
                url: "costs/command/real-costs/add-assignments",
                method: "POST",
                body
            }),
            onQueryStarted: onQueryStarted(),
        }),

        getMarginReport: build.query({
            query: ({reference, report}) => ({
                url: "costs/margin-report/get",
                method: "POST",
                body: {reference, report},
                refetchOnMountOrArgChange: false,
            }),
            providesTags: () => ["costs/margin-report"],
        }),

        deleteCostSource: build.mutation({
            query: identifier => ({
                url: "costs/command/delete-cost-source",
                method: "POST",
                body: {identifier},
            }),
            onQueryStarted: onQueryStarted("costs.cost-source-deleted"),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        setCostSourcePrice: build.mutation({
            query: ({identifier, calculatedCosts, realCosts}) => ({
                url: "costs/command/set-cost-source-price",
                method: "POST",
                body: {identifier, calculatedCosts, realCosts},
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        addMarginBookings: build.mutation({
            query: ({identifier, amounts}) => ({
                url: "costs/command/add-margin-bookings",
                method: "POST",
                body: {identifier, amounts}
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        changeMarginBooking: build.mutation({
            query: ({identifier, amount, taxRate}) => ({
                url: "costs/command/change-margin-booking",
                method: "POST",
                body: {identifier, amount, taxRate},
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        deleteMarginBooking: build.mutation({
            query: identifier => ({
                url: "costs/command/delete-margin-booking",
                method: "POST",
                body: {identifier},
            }),
            onQueryStarted: onQueryStarted("costs.margin-booking-deleted"),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        marginReportLabel: build.mutation({
            query: ({id, label}) => ({
                url: `costs/margin-report-label/${id}`,
                method: "PUT",
                body: {label}
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: () => ["costs/margin-report"],
        }),

        changeAccount: build.mutation({
            query: ({identifier, account}) => ({
                url: "costs/command/change-account",
                method: "POST",
                body: {identifier, account}
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: () => ["costs/margin-report"],
        }),
    })
});

export const {
    useItemsQuery,
    useRealCostsQuery,
    useRealCostsSaveMutation,
    useRealCostsDeleteAssignmentMutation,
    useRealCostsAddAssignmentsMutation,
    useGetMarginReportQuery,
    useDeleteCostSourceMutation,
    useSetCostSourcePriceMutation,
    useAddMarginBookingsMutation,
    useChangeMarginBookingMutation,
    useDeleteMarginBookingMutation,
    useMarginReportLabelMutation,
    useChangeAccountMutation,
    endpoints: {
        realCosts: {
            matchPending: realCostsLoading,
        },
        realCostsDeleteAssignment: {
            matchFulfilled: realCostsAssignmentDeleted,
        },
        realCostsAddAssignments: {
            matchFulfilled: realCostsAssignmentsAdded,
        }
    }
} = costsApi
