import {createUseStyles} from "react-jss"
import {accountingStyles} from "../../accounting/styles"

export const labelCol = {xs: 24, sm: 9, lg: 9}
export const percentageCol = {xs: 3, sm: 2, lg: 2, className: "text-right"}
export const priceCol = {xs: 10, sm: 4, lg: 4, className: "text-right"}
export const anzahlCol = {xs: 6, sm: 4, lg: 4, className: "text-center"}
export const actionCol = {xs: 1, sm: 1, lg: 1, className: "text-right"}

export const useStyles = createUseStyles({
    ...accountingStyles,
    panel: {
        padding: "1rem",
        "& .rs-form-horizontal .rs-form-group label.rs-control-label": {
            width: "inherit"
        }
    },
    assignment: {
        marginBottom: ".5rem",
        paddingBottom: ".5rem",
        borderBottom: "1px solid #eee"
    },
    check: {
        color: "green",
        fontWeight: 900
    }
})
