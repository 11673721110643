import React, {useContext, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Col, Divider, Form, Grid, Loader, Row} from "rsuite"
import {useRealCostsQuery} from "../store/costs-api"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import {clear, selectAssignmentIds, selectForceUpdate, selectRealCostQueryParams} from "../store/assignment-slice"
import {actionCol, anzahlCol, labelCol, percentageCol, priceCol, useStyles} from "./constants"
import CurrencySelect from "./currency-select"
import SaveButton from "./save-button"
import Assignment from "./assignment"
import LinkButtons from "./link-buttons"
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"
import AureliaContext from "../../utilities/aurelia-context";

export default function OriginForm({origin, creditor}) {
    const {currencyValueConverter, percentageValueConverter, i18n} = useContext(AureliaContext)
    const dispatch = useDispatch()
    const params = useSelector(selectRealCostQueryParams)
    const {data, isLoading, isFetching, refetch} = useRealCostsQuery({origin, ...params})
    const ids = useSelector(selectAssignmentIds)
    const forceUpdate = useSelector(selectForceUpdate)
    const {panel, check} = useStyles()

    useEffect(
        () => {
            if (isLoading) {
                dispatch(clear([], ["EUR"]))
            } else if (!isFetching) {
                dispatch(clear(data?.assignments, data?.currencies))
            }
        },
        [origin.id, origin.modelId, isFetching]
    )

    useEffect(
        () => {
            if (forceUpdate) {
                refetch()
            }
        },
        [forceUpdate]
    )

    if (isLoading || !data) {
        return (
            <Loader/>
        )
    }

    const assignments = Object.fromEntries((data.assignments ?? []).map(
        ({id, additionalInfo, destination, calculatedSum, percentage}) => [id, {
            additionalInfo, destination, calculatedSum, percentage
        }]
    ))

    return (
        <div className={"panel panel-default " + panel}>
            <Form layout="horizontal" fluid>
                <Grid fluid>
                    <Row>
                        <Col xs={18}>
                            <CurrencySelect/>
                        </Col>
                        {data?.assignments?.length && (
                            <Col xs={6} className="text-right">
                                <SaveButton origin={origin}/>
                            </Col>
                        )}
                    </Row>

                    <Divider/>

                    <Row>
                        <Col {...labelCol}><strong><StatsLabel label={data.origin}/></strong></Col>
                        <Col {...anzahlCol}>Anzahl</Col>
                        <Col {...priceCol}><strong>{currencyValueConverter.toView(data.costs)}</strong></Col>
                        <Col {...percentageCol}/>
                        <Col {...percentageCol}/>
                        <Col {...percentageCol}>kalk.</Col>
                        <Col {...actionCol}/>
                    </Row>

                    <Divider/>

                    {ids.map(id => (
                        <Assignment
                            key={id}
                            id={id}
                            origin={origin}
                            additionalInfo={assignments[id]?.additionalInfo}
                            calculatedSum={assignments[id]?.calculatedSum}
                            destination={assignments[id]?.destination}
                            percentage={assignments[id]?.percentage}
                        />
                    ))}

                    <Row>
                        <Col xs={24}>
                            <LinkButtons i18n={i18n} origin={origin} creditor={creditor}/>
                        </Col>
                    </Row>

                    <Divider/>

                    <Row>
                        {(!data.difference || 0 === data.difference.amount) ? (
                            <>
                                <Col {...labelCol}></Col>
                                <Col {...anzahlCol}/>
                                <Col {...priceCol}><SioIcon icon="fa fa-circle-check" className={check}/></Col>
                                <Col {...percentageCol}/>
                            </>
                        ) : (
                            <>
                                <Col {...labelCol}>
                                    <strong>{data.difference.amount > 0 ? "noch zuzuweisen" : "zu viel zugewiesen"}</strong>
                                </Col>
                                <Col {...anzahlCol}/>
                                <Col {...priceCol}>
                                    <strong>{currencyValueConverter.toView(data.difference)}</strong>
                                </Col>
                                <Col {...percentageCol}>
                                    <strong>{percentageValueConverter.toView(data.differencePercentage)}</strong>
                                </Col>
                            </>
                        )}

                        <Col {...percentageCol}/>

                        <Col {...priceCol}>
                            {data.calculatedSum?.amount && currencyValueConverter.toView(data.calculatedSum)}
                        </Col>

                        <Col {...actionCol}/>
                    </Row>
                </Grid>

                {isFetching && <Loader backdrop/>}
            </Form>
        </div>
    )
}
